import React, { useState, useEffect } from 'react';
import axios from 'axios';
import adrian2 from './images/adrian2.jpg';
import Footer from './Footer';


  function Shows() {
    const [showsList, setShowsList] = useState([]);
  
    useEffect(() => {
      const fetchShows = async () => {
        try {
          // const response = await axios.get('http://localhost:3001/api/shows', {
            const response = await axios.get(`/api/shows`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`
            }
          });
          setShowsList(response.data.shows); // Adjust based on how your API returns the data
        } catch (error) {
          console.error('Error fetching shows:', error);
        }
      };
  
      fetchShows();
    }, []);


return (
  <div className="flex flex-col items-center justify-center min-h-screen">
    <main className="flex-grow px-4 flex flex-col items-center justify-center text-center w-full">
      <div className="my-8">
        <div className="border-t-4 border-b-4 border-white pt-4 pb-4">
          <h1 className="text-4xl sm:text-6xl font-bold text-white inline-block mx-auto">Come see Us!</h1>
        </div>
      </div>

      <ul className="w-full">
        {showsList.map((show) => (
          <li key={show._id} className="mb-4 pb-2 flex flex-col items-center">
            <div className="border-b border-white w-full sm:max-w-5xl">
              <div className="hidden sm:grid grid-cols-3 gap-4 text-2xl text-white">
                <div>{new Date(show.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                <div>{show.venue}</div>
                <div>{show.city}</div>
              </div>
              <div className="sm:hidden">
                <div className="text-lg sm:text-xl text-white font-bold">{new Date(show.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                <div className="text-white">{show.venue} - {show.city}</div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="py-4 w-full sm:max-w-4xl">
        <img src={adrian2} alt="Adrian" className="mx-auto max-w-full h-auto" />
      </div>
    </main>
    <div className="w-full mx-auto md:pt-14 pt-8">
      <Footer />
    </div>
  </div>
);
}

export default Shows;